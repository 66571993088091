import React from 'react';

import * as styles from '../css/pages/ComplaintsProcedure.module.scss';

import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';

const ComplaintsProcedure = () => (
    <>
        <SEO title="Complaints Procedure" path="/complaints-procedure/" />
        <Layout mainClass={styles.ComplaintsProcedure}>
            <section className={styles.content}>
                <h1>Ripples Complaints Procedure</h1>
                <hr />
                <h2>Introduction</h2>
                <p>
                    We strive to provide high quality services which meet your
                    needs and exceed your expectations. If we are not getting it
                    right, please let us know. We take complaints really
                    seriously and will always respond in an honest,
                    compassionate manner. If we made a mistake, we will admit it
                    and apologise sincerely. If we need to change, we will.
                </p>
                <p>
                    In order to ensure our services remain at a high and
                    improving standard, we have a procedure through which you
                    can let us know if for any reason you are not satisfied with
                    your dealings with Ripples.
                </p>
                <h3>If you are unhappy with Ripples, please tell us</h3>
                <p>
                    If you are unhappy about any service, please speak to the
                    relevant staff member, manager or founder. If you are
                    unhappy with an individual, it is usually best to tell him
                    or her directly in the first instance. If you feel that this
                    is difficult or inappropriate then speak to the staff
                    member's manager or a founder.
                </p>
                <p>
                    Often we will be able to give you a response straight away.
                    When the matter is more complicated we will give you at
                    least an initial response within five working days.
                </p>
                <h3>Making a written complaint</h3>
                <p>
                    If you are not satisfied with our response or wish to raise
                    the matter more formally, please write to the our Head of
                    Communications. (If your complaint is about this person,
                    please write to our Chief Executive, Sean Donnelly.)
                </p>
                <p>
                    All written complaints will be logged. You will receive a
                    written acknowledgement within three working days.
                </p>
                <p>
                    The aim is to investigate your complaint properly and give
                    you a reply within ten working days, setting out how the
                    problem will be dealt with. If this is not possible, an
                    interim response will be made informing you of the action
                    taken to date or being considered.
                </p>
                <p>
                    If after we have responded you are not satisfied, please
                    write to a company Director who will report the matter to
                    the next board meeting. The board will decide on any further
                    steps to resolve the situation.
                </p>
                <h3>How to complain</h3>
                <p>
                    Email{' '}
                    <a href="mailto:hello@joinripples.org">
                        hello@joinripples.org
                    </a>
                </p>
                <p>Or</p>
                <p>
                    Write to Ripples, 97 Charlotte Street, London, W1T 4QA,
                    United Kingdom
                </p>
                <p>Or</p>
                <p>
                    Call <a href="tel:02080641259">0208 064 1259</a>
                </p>
            </section>
        </Layout>
    </>
);

export default ComplaintsProcedure;
